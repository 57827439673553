import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Button, Form, Layout, Menu, notification, Skeleton } from "antd";
import { ControlOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import LayoutContent from "../components/common/LayoutContent";
import { defaultMultiModalMessage, getFormDefaultValues } from "../components/GPTPlayground/ChatHelper";
import NewChatBox from "../components/common/NewChatBox";
import EmptyChatLoader from "../components/common/EmptyChatLoader";
import ChatsBox from "../components/GPTPlayground/chat/ChatsBox";
import SelectGPTEnv from "../components/common/SelectGPTEnv";
import InputPromptBox from "../components/common/InputPromptBox";
import { deployments, TTC_SELECTION, TTC_MESSAGE } from "../constants";
import HelperButtonController from "../components/common/HelperButtonController";
import HelperContentList from "../components/common/HelperContentList";
import { BoldlogoStyle, BottomContainerStyled, FormStyled, LayoutStyled } from "../components/Styles";
import BotsSlideParameters from "../components/common/BotsSlideParameters";
import PromptText from "../components/common/PromptText";
import { rawChatCompletions } from "../service/hermes.service";
import MessageContainer from "../components/common/MessageContainer";

const Hermes = ({ isLoggedIn, handleLogout }) => {
  const [api, contextHolder] = notification.useNotification();
  const [formInitValues, setFormInitValues] = useState({ deployment: "Select Document Section" });
  const [showChatLoader, setShowChatLoader] = useState(false);
  const [promptInput, setPromptInput] = useState(TTC_MESSAGE["DEFAULT"]);
  const [promptTTCContent, setPromptTTCContent] = useState("{{ttc-content}}");
  const [TTCSection, setTTCSection] = useState("DEFAULT");

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");

  const [form] = Form.useForm();
  const chatBoxRef = useRef(null);
  const inputPromptRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [openParameter, setOpenParameter] = useState(false);
  const [stateBindToDeploymentModel, setStateBindToDeploymentModel] = useState({ deployment: null, hideFormElements: [] });
  const suggestedInputs = [
    "Script Writer for animated films in California",
    "Cashier in Logistics domain in UK",
    "Nurse in terminal illness related field",
    "Fictional movie content writer",
  ];
  const suggestedCardTTCKey = ["EXPR", "SKLLS", "SUMM", "EDUC"];

  const openNotificationWithIcon = ({ type, message, description, duration = 8 }) => {
    api[type]({ message, description, duration });
  };

  const sendMessage = async (content, clickedSuggetionCardIndex = null) => {
    content = content.trim();
    if (content === "") {
      openNotificationWithIcon({
        type: "error",
        message: "Invalid or Empty Prompt",
        description: "Write Something in the prompt input box",
      });
      return;
    }

    try {
      if (!messages.length) {
        setShowChatLoader(true);
      } else {
        setLoading(true);
      }

      const displayDate = new Date().toLocaleString('en-IN', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });

      let TTCObj = { value: TTCSection?.value || TTCSection, text: TTCSection?.text || TTCSection };
      if (clickedSuggetionCardIndex !== null && clickedSuggetionCardIndex !== undefined) {
        TTCObj = TTC_SELECTION.find(e => e.value === suggestedCardTTCKey[clickedSuggetionCardIndex]);
        form.setFieldsValue({ deployment: TTCObj.value });
        handleTTCSelectionChange(TTCObj.value);
      }

      const data = await rawChatCompletions(content, `${promptInput}\n${promptTTCContent}`, TTCObj.value);

      if (data?.llm_search_resp && Array.isArray(data.hits)) {
        const lists = data.hits.map((hit, index) => `${index + 1}. ${hit.text}`).join('\n');
        const messagesArray = JSON.parse(data.llm_search_resp);

        const newUserMessageData = {
          role: "user",
          content: `
            <div class="custom-prompt-wrapper">
              <p class='custom-prompt-head'>Document Section: <strong>${TTCObj.text}</strong></p>
              <h4>Final Prompt</h4>
              <h5>${content}</h5>
              <div>${lists}</div>
            </div>
          `,
          copyContent: `${content}\n${lists}`,
          timeStamp: displayDate,
        };

        const newAssistantData = {
          role: "assistant",
          content: messagesArray.Messages[0].Message.Content.replace(/\n/g, "<br>"),
          timeStamp: displayDate,
        };

        setMessages(prevMessages => [...prevMessages, newUserMessageData, newAssistantData]);
      } else {
        throw new Error("Invalid API response structure");
      }
    } catch (error) {
      console.error("Error in sendMessage:", error);

      openNotificationWithIcon({
        type: "error",
        message: "API Error",
        description: "Failed to fetch the response. Please try again later.",
      });
    } finally {
      setLoading(false);
      setShowChatLoader(false);
    }

    setInput("");
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (!event.shiftKey && event.key === "Enter" && !stateBindToDeploymentModel.deprecate) {
      event.preventDefault();
      sendMessage(input);
    }
  };

  const handleTTCSelectionChange = (value) => {
    setPromptInput(TTC_MESSAGE[value]);
    setTTCSection(...TTC_SELECTION.filter(e => e.value === value));
  };

  const handleResetChatButton = (e) => {
    setMessages([]);
    setTTCSection("DEFAULT");
    setPromptInput(TTC_MESSAGE["DEFAULT"]);
  };

  return (
    <LayoutStyled>
      {contextHolder}
      <LayoutContent
        isLoggedIn={isLoggedIn}
        handleLogout={handleLogout}
        OnNewChatHandler={handleResetChatButton}
        newChat={messages.length ? true : false}
      >
        <FormStyled layout="vertical" form={form} name="basic" initialValues={formInitValues}>
          <MessageContainer avatarStyle={{ backgroundImage: 'url("../hermes.png")', backgroundSize: 'cover' }}>
            {
              !messages.length && !showChatLoader ? (
                <NewChatBox
                  sendMessage={sendMessage}
                  suggestedCardHandle={sendMessage}
                  disabled={false}
                  suggestedInputs={suggestedInputs}
                  botNameNode={<>
                    Hermes, <BoldlogoStyle>BOLD</BoldlogoStyle>'s TTC Bot
                  </>}
                />
              ) : !messages.length && showChatLoader ? (
                <EmptyChatLoader />
              ) : (
                <ChatsBox
                  messages={messages}
                  loading={loading}
                  setInput={setInput}
                  inputPromptRef={inputPromptRef}
                  allowConvertHTML={false}
                  showCopyForEdit={true}
                  showCopyForAnswer={true}
                  openNotificationWithIcon={openNotificationWithIcon}
                />
              )
            }
          </MessageContainer>
          <BottomContainerStyled customstyle={{ paddingBottom: "20px" }}>
            <SelectGPTEnv selectItrator={TTC_SELECTION} onSelectionChange={handleTTCSelectionChange} />
            <PromptText
              promptInput={promptInput}
              setPromptInput={setPromptInput}
              promptTTCContent={promptTTCContent}
              setPromptTTCContent={setPromptTTCContent}
            />
            <InputPromptBox
              handleInputChange={handleInputChange}
              input={input}
              handleKeyPress={handleKeyPress}
              setOpenParameter={setOpenParameter}
              openParameter={openParameter}
              handleSendMessage={() => sendMessage(input)}
              inputPromptRef={inputPromptRef}
            />
          </BottomContainerStyled>
        </FormStyled>
      </LayoutContent>
    </LayoutStyled>
  );
};

export default Hermes;
