import Cookies from "js-cookie";

const HR_API_ENDPOINT = "https://chat.bold.com/api/v1/vectors/hrbot/query";

const authToken = () => `Bearer ${Cookies.get("auth_user")}`;


//#region chat interface result
async function rawChatCompletions(input, EarlierChatContext = null) {
  //example requestJson
  //{"messages":[{"role":"user","content":"answer questions about india"}],"temperature":0.7,"top_p":0.95,"frequency_penalty":0,"presence_penalty":0,"max_tokens":800,"stop":null}

  const rawAPIPayLoad = {
    InputQueryText: input,
    EarlierChatContext: EarlierChatContext,
  };
  
  const res = await fetch(HR_API_ENDPOINT, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify(rawAPIPayLoad),
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
  });
  let data = await res.json();
  return data;
}
//#endregion chat completions endpoint


export {rawChatCompletions}