const defaultParams = [
  {
    temperature: 0.7,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
    best_of: 1,
    max_tokens: 500,
    max_history: 5,
    stop: null,
    post_text_check: true,
    pre_text_check: true,
    post_text: "",
    pre_text: "",
  },
];

const deployments = [
  {
    text: "gpt-4 (0125)",
    value: "gpt-4-generic",
    max_token_limit: 4000,
    useForChat: true,
    useForPrompt: true,
    apiTarget: { p: "CHAT_COMPLETION_PATH", 'c':'CHAT_COMPLETION_PATH' },
    usageInstructions: [
      "GPT-4 may come with high costs. Consider alternatives depending on your needs. Use with care.",
    ],
  },
  {
    text: "gemini pro (1.0)",
    value: "gemini-pro",
    max_token_limit: 8000,
    useForChat: true,
    useForPrompt: true,
    apiTarget: { p: "COMPLETION_PATH", 'c':'CHAT_COMPLETION_PATH' },
  },
  {
    text: "gemini pro vision(1.0)",
    value: "gemini-pro-vision",
    max_token_limit: 2000,
    useForChat: false,
    useForPrompt: false,
    usageInstructions: [
      "<b>Deprecated. Please use gemini-15-flash instead.</b>",
      "Supported files (png, jpeg, mov, mpeg, mp4, mpg, avi, wmv, mpegps, flv)",
      "You may specify either text only, text and up to 16 images, or text and 1 video",
      "Max number of files allowed are 16",
    ],
    deprecate: true,
  },
  {
    text: "gemini flash(1.5)",
    value: "gemini-15-flash",
    max_token_limit: 2000,
    useForChat: true,
    useForPrompt: false,
    apiTarget: { 'c':'MULTI_MODAL_CHAT_COMPLETION_PATH' },
    usageInstructions: [
      "Supported files (png, jpeg, mov, mpeg, mp4, mpg, avi, wmv, mpegps, flv)",
      "You may specify either text only, text and up to 16 images, or text and 1 video",
      "Max number of files allowed are 16",
    ],
  },
  {
    text: "gpt-4 vision",
    value: "gpt-4-vision-preview",
    max_token_limit: 4000,
    useForChat: false,
    useForPrompt: false,
    usageInstructions: [
      "<b>Deprecated. Please use gpt-4o instead.</b>",
      "Supports only image files (.png, .jpeg, .jpg, .webp and .gif)",
      "Max number of files allowed are 10",
    ],
    deprecate: true,
  },
  {
    text: "gpt-4o (2024-05-13)",
    value: "gpt-4o",
    max_token_limit: 4096,
    useForChat: true,
    useForPrompt: true,
    apiTarget: { p: "CHAT_COMPLETION_PATH", 'c':'MULTI_MODAL_CHAT_COMPLETION_PATH' },
    usageInstructions: [
      "<b>GPT-4o latest large GA model</b>",
      "Supports only image files (.png, .jpeg, .jpg, .webp and .gif)",
      "Max number of files allowed are 10",
    ],
  },
  {
    text: "gpt-4o-mini (2024-07-18)",
    value: "gpt-4o-mini",
    max_token_limit: 16384,
    useForChat: true,
    useForPrompt: true,
    apiTarget: { p: "CHAT_COMPLETION_PATH", 'c':'MULTI_MODAL_CHAT_COMPLETION_PATH' },
    usageInstructions: [
      "<b>GPT-4o latest small GA model</b>",
      "Supports only image files (.png, .jpeg, .jpg, .webp and .gif)",
      "Max number of files allowed are 10",
    ],
  },
];

const getDeploymentByValue = (value) => {
  return deployments.find((x) => x.value === value);
};
const config = {
  API_HOST: process.env.REACT_APP_API_HOST,
  COMPLETION_PATH: "/gpt3/deployment_name/completions",
  CHAT_COMPLETION_PATH: "/gpt4/deployment_name/chatCompletion",
  MULTI_MODAL_CHAT_COMPLETION_PATH:
    "/gpt4/deployment_name/chatCompletionMultiModal",
  CALCULATE_TOKEN_PATH: "/gpt3/calculateToken",
  USER_PROFILE_PATH: "/auth/token",
  LOGON: "/auth/login",
  PROMPTS: "/queries",
  USER: "/users",
  ENV: process.env.NODE_ENV,
  STORAGE_URI: "https://gptplaygrounddevst.blob.core.windows.net",
};

const TTC_SELECTION = [
  {
    text: "Work Experience",
    useForChat: true,
    value: "EXPR"
  },
  {
    text: "Skills",
    useForChat: true,
    value: "SKLLS"
  },
  {
    text: "Summary",
    useForChat: true,
    value: "SUMM"
  },
  {
    text: "Certification",
    useForChat: true,
    value: "CERT"
  },
  {
    text: "Education",
    useForChat: true,
    value: "EDUC"
  },
  {
    text: "Accomplishments",
    useForChat: true,
    value: "ACCM"
  },
  {
    text: "Affilations",
    useForChat: true,
    value: "AFIL"
  }
];

const TTC_MESSAGE = {
  "CERT" : "Please provide meaningful and relevant list of certification not more than 10-15 words separated by newline based on following context.",
  "EXPR" : "Please provide 5-6 meaningful, concise and short description in 1-2 lines each seperated by newline about work experience based on the following context.",
  "SUMM" : "Please generate precise summary to be mentioned in resume in 2-4 lines each separated by newline with 30-50 words per line in similar tone based on context",
  "SKLLS" : "Please suggest similar type of skills 5-8 words seperated by newline in similar tone based on following context",
  "ACCM" : "Please provide meaningful and relevant list of accomplishment or achievements not more than 10-15 words separated by newline based on following context.",
  "EDUC" : "Please suggest relevant education or certification details in same tone not more than 15-20 words separated by newline based on following context.",
  "AFIL" : "Please provide top affilations in same tone not more than 10-15 words separated by newline based on following context.",
  "DEFAULT" : "Please generate content in similar tone in 3-4 lines with 30-40 words per line separated by newline based on following context."
};

export { deployments, defaultParams, config, getDeploymentByValue, TTC_SELECTION, TTC_MESSAGE };
