import React, { useCallback } from "react";
import { Avatar, Button, Skeleton } from "antd";
import { UserOutlined, EditOutlined, CopyOutlined, LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import styled from "styled-components";
import FormattedMimeMessage from "./FormattedMimeMessage";
import { convertToHTML, convertToHTMLWithOL } from "../ChatHelper";

const ChatsBox = React.memo(({
  className,
  messages,
  loading,
  setInput,
  inputPromptRef,
  allowConvertHTML = true,
  showCopyForEdit = false,
  showCopyForAnswer = false,
  editUserMessage = false,
  openNotificationWithIcon,
  showThumbsUpDown = false,
  isDisclaimer = false,
  disClaimer
}) => {

  const onEditPromptClick = useCallback((content) => {
    if (!content.includes('#MMM#')) {
      setInput(content);
      inputPromptRef?.current?.focus({ cursor: 'end' });
    }
  }, [setInput, inputPromptRef]);

  const copyToClipboard = useCallback((value) => {
    const cleanedText = value.replace(/^\d+\.\s+|<[^>]*>/gm, '').trim();
    navigator.clipboard.writeText(cleanedText).then(() => {
      openNotificationWithIcon({
        type: "success",
        message: "Copied to Clipboard",
      });
    }).catch(() => {
      openNotificationWithIcon({
        type: "error",
        message: "Failed to Copy",
      });
    });
  }, [openNotificationWithIcon]);

  return (
    <ChatsBoxStyled className={className}>
      {messages.map((message, index) => (
        <div className={`message ${message.role === "user" ? "right" : "left"}`} key={index}>
          {message.role === "user" ? <Avatar icon={<UserOutlined />} /> : <div className="avatar"></div>}
          <div className="text-and-icon-wrapper">
            {message.role === "user" && editUserMessage && (
              <Button
                type="text"
                onClick={() => onEditPromptClick(message.content)}
                shape="circle"
                icon={<EditOutlined />}
                className="hover-show-icon"
              />
            )}
            <div className="text-wrapper">
              {showCopyForEdit && message.role === "user" && (message?.copyContent || message?.content) && (
                <Button
                  type="text"
                  onClick={() => copyToClipboard(message.copyContent || message.content)}
                  shape="circle"
                  icon={<CopyOutlined />}
                  className="copy-button"
                />
              )}
              {message?.content && !message.content.includes('#MMM#') ? (
                allowConvertHTML ? (
                  <div className="text" dangerouslySetInnerHTML={{ __html: convertToHTML(message.content) }} />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: convertToHTMLWithOL(message.content) }} />
                )
              ) : (
                <FormattedMimeMessage message={message.content} />
              )}
              {message?.HelpUrls && (
                <ul className="timestamp">
                  {Object.entries(message.HelpUrls).map(([key, value], index) => (
                    <li key={index}>
                      <a href={key}>{value}</a>
                    </li>
                  ))}
                </ul>
              )}
              <div className="action-btn-wrapper">
                {message?.timeStamp && <div className="timestamp">{message.timeStamp}</div>}
                {showCopyForAnswer && message.role === "assistant" && (
                  <Button
                    type="text"
                    onClick={() => copyToClipboard(message.copyContent || message.content)}
                    icon={<CopyOutlined />}
                    className="copy-button"
                  />
                )}
                {showThumbsUpDown && (
                  <>
                    <Button
                      type="text"
                      onClick={() => copyToClipboard(message.copyContent || message.content)}
                      icon={<LikeOutlined />}
                      className="copy-button"
                    />
                    <Button
                      type="text"
                      onClick={() => copyToClipboard(message.copyContent || message.content)}
                      icon={<DislikeOutlined />}
                      className="copy-button"
                    />
                  </>
                )}
              </div>
              <>
              {isDisclaimer && message.role !== "user"  &&
                disClaimer
                }</>
            </div>
          </div>
        </div>
      ))}
      {loading && (
        <div className="message left">
          <div className="avatar"></div>
          <Skeleton active />
        </div>
      )}
    </ChatsBoxStyled>
  );
});

const ChatsBoxStyled = styled.div`
  width: 100%;
  padding-top: 50px;
  max-width: 750px;
  margin: 0 auto;
  .ant-avatar.ant-avatar-icon {
    background-color: ${(props) => props.theme.colors.primary};
    height: 35px;
    width: 35px;
    flex-shrink: 0;
  }
  .text-and-icon-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 85%;
    .hover-show-icon {
      opacity: 0;
      visibility: hidden;
    }
    &:hover .hover-show-icon {
      opacity: 1;
      visibility: visible;
    }
  }
  .message {
    display: flex;
    margin-bottom: 40px;
    transition: all 0.5s linear;
    gap: 20px;
    align-items: flex-start;
    .copy-button {
      cursor: pointer;
      padding: 0px;
    }
    .action-btn-wrapper {
      display: flex;
      padding-top: 5px;
    }
  }
  .appeared {
    opacity: 1;
  }
  .timestamp {
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
    & a {
      color: ${(props) => props.theme.colors.brandTeal};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .right {
    flex-direction: row-reverse;
    .text-wrapper {
      border-radius: 10px;
      position: relative;
      border: 1px solid rgba(71, 94, 132, 0.3);
      background: #fff;
      padding: 16px 16px 12px;
      .custom-prompt-wrapper {
        ol {
          list-style: decimal;
          font-style: italic;
          padding-left: 20px;
        }
      }
      .custom-prompt-head {
        padding-right: 30px;
      }
      .copy-button {
        position: absolute;
        z-index: 2;
        right: 10px;
        top: 5px;
      }
    }
    .text {
      white-space: pre-wrap;
    }
  }
  .left {
    a {
      color: ${(props) => props.theme.colors.brandOrange};
      :hover {
        text-decoration: underline;
      }
    }
    .text {
      & > * {
        white-space: pre-wrap;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    h1, h2, h3, h4, h5, h6, strong {
      font-weight: 600;
    }
    ul {
      list-style: inside disc;
      li {
        padding-left: 10px;
      }
    }
    .avatar {
      background-image: url("../icon-bold.svg");
      border-radius: 50px;
      border: 1px solid rgba(87, 108, 141, 0.5);
      background-color: #fff;
      background-size: 60%;
      height: 35px;
      width: 35px;
      background-repeat: no-repeat;
      background-position: center center;
      flex-shrink: 0;
    }
    .timestamp ~ .copy-button {
      margin-left: 5px;
    }
  }
`;

export default ChatsBox;
