import React, { useState, useContext, useEffect, useRef } from "react";
import { Form } from "antd";
import { ControlOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { defaultParams, deployments, getDeploymentByValue } from "../../../constants";
import UserContext from "../../../context";
import { defaultMultiModalMessage, getNewState } from "../ChatHelper";
import { createNewChatHistory, getAllChatTitlesForUser, rawChatCompletions, updateChatThreadById} from "../../../service";
import NewChatBox from "../../common/NewChatBox";
import ChatsBox from "./ChatsBox";
import InputPromptBox from "../../common/InputPromptBox";
import EmptyChatLoader from "../../common/EmptyChatLoader";
import SelectGPTEnv from "../../common/SelectGPTEnv";
import HelperButtonController from "../../common/HelperButtonController";
import BotsSlideParameters from "../../common/BotsSlideParameters";
import BotsInputParameter from "../../common/BotsInputParameter";
import { BoldlogoStyle, BottomContainerStyled, FormStyled } from "../../Styles";
import MessageContainer from "../../common/MessageContainer";

const ChatBuilder = (props) => {
  const {
    showChatLoader,
    setShowChatLoader,
    selectedKeys, setSelectedKeys,
    navUpdateLoader, setNavUpdateLoader,
    navItems, setNavItems,
    messages,
    setMessages,
    collapsed,
    setCollapsed,
    formInitValues,
    setFormInitValues,
    input,
    setInput,
    fileList, 
    setFileList,
    newUserMessage,
    setNewUserMessage,
    selectedChatHistoryValue, 
    setSelectedChatHistoryValue,
    openNotificationWithIcon,
    api,
    onNewChatClick
  } = props

  const [chatBoxState, setChatBoxState] = useState(null);
  const [loading, setLoading] = useState(false); // this is while a prompt is being processed at GPT
  const [stateBindToDeploymentModel, setStateBindToDeploymentModel] = useState({ deployment: null });
  const [form] = Form.useForm();
  const [openParameter, setOpenParameter] = useState(false);

  const { userContext, setUserContext } = useContext(UserContext);
  const user = userContext.user;
  const inputPromptRef = useRef(null);
  const [editItemId, setEditItemId] = useState(false);


  useEffect(() => {
    //in edit mode we will get cost and
    form.setFieldsValue(formInitValues);
    setStateBindToDeploymentModel(() => getNewState(formInitValues));
    setSelectedChatHistoryValue({ chats: formInitValues.chats, id: formInitValues.id });
    setChatBoxState({
      sessionStartIndex: 0,
      showUpload: ["gemini-15-flash", "gpt-4-vision-preview"].includes(stateBindToDeploymentModel.deployment),
      uploadFileTypeSupported: stateBindToDeploymentModel.uploadFileTypeSupported,
    });
  }, [formInitValues]);

  useEffect(() => {
    //load from history if current messages are blank

    if (selectedChatHistoryValue.chats) {
      setMessages(selectedChatHistoryValue.chats);
    }

    setChatBoxState((p) => {
      return {
        ...p,
        showUpload: ["gemini-15-flash", "gpt-4o"].includes(stateBindToDeploymentModel.deployment),
      };
    });
    setShowChatLoader(false)
  }, [selectedChatHistoryValue, stateBindToDeploymentModel.deployment]);

  const removeFirstElement = (arr, condition) => {
    const index = arr.findIndex(condition);
    if (index === -1) {
      return arr;
    }
    if (index == 0) {
      arr.shift();
    }
    return arr;
  };

  const createBodyForRawChatCompletions = (values, messagesToSendToServer) => {
    let providerType = "AzureOpenAI";
    if (["gemini-15-flash", "gemini-pro"].includes(values.deployment)) {
      providerType = "GoogleBard";
    }

    const body = {
      prompt: values.prompt,
      temperature: values.temperature,
      top_p: values.top_p,
      frequency_penalty: values.frequency_penalty,
      presence_penalty: values.presence_penalty,
      max_tokens: values.max_tokens,
      stop: values.stop,
      messages: messagesToSendToServer,
      provider_type: providerType,
    };
    //best_of is not a valid argument for model "gpt-35-turbo-chatgpt"
    if (!["gpt-35-turbo-chatgpt", "gpt-4o"].includes(values.deployment)) {
      body["best_of"] = values.best_of;
    }
    return body;
  };

  const sendMessage = async (content = "") => {
    if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
      openNotificationWithIcon({
        type: "error",
        message: "Start a new chat first, or select from existing ones.",
        description: "Please click on new chat button from side menu to start a new conversation. You can also select one from existing.",
      });
      return;
    }
    if (content.trim() === "") {
      openNotificationWithIcon({
        type: "error",
        message: "Invalid or Empty Prompt",
        description: "Write Something in the prompt input box",
      });
      return;
    }
    const nextMessage = newUserMessage;
    const messageForUIThread = {
      role: "user",
      content: "",
    };
    if (chatBoxState.showUpload) {
      //this is multimodal
      //text will be contentItem node
      const m = {
        ContentType: "Text",
        Text: content.trim(),
      };

      nextMessage.contentItems.unshift(m);
      //update UI after the call is done
      //in UI we will append {role,content}
      //while for server {role, contentitems}
      let formatMessage = "";
      nextMessage.contentItems.forEach((node) => {
        if (node.ContentType == "Text") {
          formatMessage = node.Text;
        } else if (node.ContentType == "InlineData") {
          formatMessage = formatMessage + "\n#MMM#" + `data:${node.InlineData.Mimetype};base64,${node.InlineData.Data}`;
        }
      });
      messageForUIThread.content = formatMessage;
    } else {
      nextMessage.content = content.trim();
      messageForUIThread.content = content.trim();
    }
    let entireFormValues = form.getFieldValue();

    //here check if we have contentitems as well
    let messagesToSendToServer = [];

    //max items to take in session.

    let msgsToAddCount = entireFormValues.max_history;

    //Add last x messages for context

    for (let index = messages.length - 1; index >= chatBoxState.sessionStartIndex; index--) {
      if (msgsToAddCount == 0) break;
      let msg = messages[index];
      msg = {
        ...msg,
        content: msg.role === "user" && msg.content ? msg.content.split("#MMM#")[0] : msg.content,
      };
      messagesToSendToServer.push(msg);
      msgsToAddCount--;
    }

    messagesToSendToServer = messagesToSendToServer.reverse();
    messagesToSendToServer.push(nextMessage);

    //in case of gemini-pro model we cannot have first message with user role
    if (["gemini-15-flash", "gemini-pro"].includes(entireFormValues.deployment)) {
      messagesToSendToServer = removeFirstElement(messagesToSendToServer, (elm) => elm.role !== "user");
    }

    setNewUserMessage(defaultMultiModalMessage());
    
    setMessages((prevMessages) => [...prevMessages, messageForUIThread]);
    setFileList([]);
    try {
      setLoading(true);
      // Call Api for response
      rawChatCompletions(
        entireFormValues.deployment,
        createBodyForRawChatCompletions(
          entireFormValues, messagesToSendToServer
        )
      ).then((data) => {
        if (data.choicesText) {
          //update chat history of the current chat if response is fine
          var returnMsg = data.choicesText[0];
          // let contentMessageParts = messageForUIThread.content.split("#MMM#");
          // let cM = ''
          // contentMessageParts.forEach((m, i) => {
          //   if (i > 0) {
          //     cM = cM+ '#MMM#' + m // '#PLH#'
          //   }else{
          //     cM = m;
          //   }
          // });
          // let lastUserMessageToAppendInChatThread = {
          //   ...messageForUIThread,
          //   content: cM
          // };

          setMessages((prevMessages) => [...prevMessages, returnMsg]);
          updateChatThreadById(selectedChatHistoryValue.id, [messageForUIThread, returnMsg]);
          setInput("");
        } else {
          // show error here.
          const msgToShow = data.message || "Error in new chat";
          openNotificationWithIcon({
            type: "error",
            message: "Error",
            description: msgToShow,
          });
          removeLastMessageOnError();
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching bot response:", error);
      openNotificationWithIcon({
        type: "error",
        message: "Something went wrong",
        description: error.message,
      });
      setLoading(false);
      removeLastMessageOnError();
    }
    
  };

  const removeLastMessageOnError = () => {
    //remove the last message from the history if it is of role=user
    setMessages((prevMessages) => {
      if (prevMessages.length > 0 && prevMessages[prevMessages.length - 1].role === "user") {
        prevMessages.pop();
        return [...prevMessages];
      }
      return [...prevMessages];
    });
  };

  const onDeploymentChange = (value, option) => {
    onNewChatClick(null, value);
    openNotificationWithIcon({
      type: "info",
      message: "Session reset",
      description: "New chat session has started",
    });
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const handleKeyPress = (event) => {
    if (!event.shiftKey && event.key === "Enter" && !stateBindToDeploymentModel.deprecate) {
      event.preventDefault();
      if (input.trim() === "") {
        openNotificationWithIcon({
          type: "error",
          message: "Invalid or Empty Prompt",
          description: "Write Something in the prompt input box",
        });
        return;
      }
      if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
        suggestedCardHandle(input)
      }
      else{
        sendMessage(input);
        setInput("");
      }
    }
  };


  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

  const onFileUploadChange = async (info) => {
    //console.log(info);

    //validations
    //Goolge Visiosn: should allow  text, image, video & pdf rest return false
    //both image and video are not allowed
    //GPT: should allow text, and image
    let images = info.fileList.filter((f) => f.type.startsWith("image/"));
    let videos = info.fileList.filter((f) => f.type.startsWith("video/"));
    let pdfs = info.fileList.filter((f) =>
      f.type.startsWith("application/pdf")
    );
    if (stateBindToDeploymentModel.deployment === "gemini-15-flash") {
      if (
        !info.file.type.startsWith("image/") &&
        !info.file.type.startsWith("application/pdf") &&
        !info.file.type.startsWith("video/")
      ) {
        openNotificationWithIcon({
          type: "error",
          message: "Only supports image, pdf & video",
        });
        return false;
      }

      if ((images.length > 0 || pdfs.length > 0) && videos.length > 0) {
        openNotificationWithIcon({
          type: "error",
          message: "Video's can accompany only text message",
        });
        return false;
      }
      if (videos.length > 1) {
        openNotificationWithIcon({
          type: "error",
          message: "Supports one video file",
        });
        return false;
      }
    } else if (!info.file.type.startsWith("image/")) {
      openNotificationWithIcon({
        type: "error",
        message: "Only supports image",
      });
      return false;
    }

    setFileList([...images, ...pdfs, ...videos]);
    const nextContentItem = {
      ContentType: "InlineData",
      InlineData: {
        Mimetype: null,
        Data: null,
      },
    };
    if (!info.file.status && info.file.status !== "removed") {
      nextContentItem.InlineData.Mimetype = info.file.type;
      nextContentItem.InlineData.Data = await getBase64(info.file);
      setNewUserMessage((prev) => {
        const cItems = [...prev.contentItems, nextContentItem];
        return {
          ...prev,
          contentItems: cItems,
        };
      });
    }
  };

  const suggestionSendMessage = (content, chatData) => {
    if (chatData.id == null || chatData.id == 0) {
      openNotificationWithIcon({
        type: "error",
        message: "Start a new chat first, or select from existing ones.",
        description: "Please click on new chat button from side menu to start a new conversation. You can also select one from existing.",
      });
      return;
    }
    if (content.trim() === "") {
      openNotificationWithIcon({
        type: "error",
        message: "Invalid or Empty Prompt",
        description: "Write Something in the prompt input box",
      });
      return;
    }
    const nextMessage = newUserMessage;
    const messageForUIThread = {
      role: "user",
      content: "",
    };
    if (chatBoxState.showUpload) {
      //this is multimodal
      //text will be contentItem node
      const m = {
        ContentType: "Text",
        Text: content.trim(),
      };

      nextMessage.contentItems.unshift(m);
      //update UI after the call is done
      //in UI we will append {role,content}
      //while for server {role, contentitems}
      let formatMessage = "";
      nextMessage.contentItems.forEach((node) => {
        if (node.ContentType == "Text") {
          formatMessage = node.Text;
        } else if (node.ContentType == "InlineData") {
          formatMessage = formatMessage + "\n#MMM#" + `data:${node.InlineData.Mimetype};base64,${node.InlineData.Data}`;
        }
      });
      messageForUIThread.content = formatMessage;
    } else {
      nextMessage.content = content.trim();
      messageForUIThread.content = content.trim();
    }
    let entireFormValues = form.getFieldValue();

    //here check if we have contentitems as well
    let messagesToSendToServer = [];

    //max items to take in session.

    let msgsToAddCount = entireFormValues.max_history;

    //Add last x messages for context

    for (let index = messages.length - 1; index >= chatBoxState.sessionStartIndex; index--) {
      if (msgsToAddCount == 0) break;
      let msg = messages[index];
      msg = {
        ...msg,
        content: msg.role === "user" && msg.content ? msg.content.split("#MMM#")[0] : msg.content,
      };
      messagesToSendToServer.push(msg);
      msgsToAddCount--;
    }
    messagesToSendToServer = messagesToSendToServer.reverse();
    messagesToSendToServer.push(nextMessage);

    //in case of gemini-pro model we cannot have first message with user role
    if (["gemini-15-flash", "gemini-pro"].includes(entireFormValues.deployment)) {
      messagesToSendToServer = removeFirstElement(messagesToSendToServer, (elm) => elm.role !== "user");
    }
    setNewUserMessage(defaultMultiModalMessage());

    // setMessages((prevMessages) => [...prevMessages, messageForUIThread]);
    setFileList([]);
    setNavUpdateLoader(true)
    try {
      setLoading(true);
      // Call Api for response
      rawChatCompletions(
        entireFormValues.deployment,
        createBodyForRawChatCompletions(
          entireFormValues, messagesToSendToServer
        )
      ).then((data) => {
        if (data.choicesText) {
          //update chat history of the current chat if response is fine
          var returnMsg = data.choicesText[0];
          // let contentMessageParts = messageForUIThread.content.split("#MMM#");
          // let cM = ''
          // contentMessageParts.forEach((m, i) => {
          //   if (i > 0) {
          //     cM = cM+ '#MMM#' + m // '#PLH#'
          //   }else{
          //     cM = m;
          //   }
          // });
          // let lastUserMessageToAppendInChatThread = {
          //   ...messageForUIThread,
          //   content: cM
          // };

          setMessages((prevMessages) => [...prevMessages, returnMsg]);
          updateChatThreadById(chatData.id, [messageForUIThread, returnMsg]);
          setInput("");
          if (chatData) {
            const dep = getDeploymentByValue(chatData.deployment);
            const values = {
              deployment: chatData.deployment,
              deployment_max_token_limit: dep?.max_token_limit,
              ...chatData.params[0],
              chats: [messageForUIThread, returnMsg],
              id: chatData.id,
              usageInstructions: dep?.usageInstructions,
              deprecate: dep?.deprecate || false,
            };
      
            setFormInitValues({ ...values });
            setUserContext({ ...userContext, activeChatHistoryId: chatData.id });
          }
          getAllChatTitlesForUser(user).then((data) => {
            if (data == null || data.code) return;
      
            var uiArray = [];
            data.forEach((item) => {
              uiArray.push({
                key: item.id,
                label: item.name,
              });
            });
            setSelectedKeys([chatData.id.toString()]);
            setNavItems(uiArray);
            setNavUpdateLoader(false);
          });
        } else {
          // show error here.
          const msgToShow = data.message || "Error in new chat";
          openNotificationWithIcon({
            type: "error",
            message: "Error",
            description: msgToShow,
          });
          removeLastMessageOnError();
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error fetching bot response:", error);
      openNotificationWithIcon({
        type: "error",
        message: "Something went wrong",
        description: error.message,
      });
      setLoading(false);
      setNavUpdateLoader(false)
      removeLastMessageOnError();
    }
  }

  const suggestedCardHandle = async (content) => {
    if (!user) {
      console.log("User is null.");
      return;
    }
    //save the details in chat history api
    if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
      setLoading(true)
      setInput('')
      setMessages([{
        role: "user",
        content: content,
      }]);
      let newChatTitle = "new Chat- " + Math.trunc(Math.random() * 100000);
      createNewChatHistory(user, newChatTitle, formInitValues.deployment, defaultParams).then((data) => {
        setShowChatLoader(false);
        if (data.id) {
          suggestionSendMessage(content, data)
        } else {
          // show error here.
          const msgToShow = data.message || "Error in starting new chat";
          openNotificationWithIcon({
            type: "error",
            message: "Error",
            description: msgToShow,
          });
          setNavUpdateLoader(false)
        }
      });
    }
    else{
      sendMessage(content)
    }
  }

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (selectedChatHistoryValue.id == null || selectedChatHistoryValue.id == 0) {
      suggestedCardHandle(input)
    }
    else{
      sendMessage(input)
    }
  }

  const suggestedInputs = [
    "What are the best practices for writing clean and maintainable code?",
    "Can you provide tips on effective time management and productivity in the workplace?",
    "What are some ways I can prioritize and organize my work day?",
    "I need to write a company wide email informing every one of a recent win we had in product and markeing."
  ]


  return (
    <FormStyled layout="vertical" form={form} name="basic" initialValues={formInitValues}>
      <MessageContainer>
        {
          !messages.length
          ? 
          <NewChatBox 
            sendMessage={sendMessage}
            suggestedInputs={suggestedInputs}
            suggestedCardHandle={suggestedCardHandle} disabled={navUpdateLoader} botNameNode={<><BoldlogoStyle>BOLD</BoldlogoStyle>'s GPT PlayGround</>} />  // Suggestion
          : 
          showChatLoader ?
          <EmptyChatLoader /> // Simple Skelton with User and Chat Response Box
          :
          <ChatsBox 
            messages={messages} 
            loading={loading} 
            setInput={setInput} 
            inputPromptRef={inputPromptRef} 
            editUserMessage={true}
            openNotificationWithIcon={openNotificationWithIcon}
            showCopyForAnswer={true}
          /> // Chat Box
        }
      </MessageContainer>
      <BottomContainerStyled customstyle={{paddingBottom: "20px"}}>
        <SelectGPTEnv onSelectionChange={onDeploymentChange} selectItrator={deployments} />
        <InputPromptBox 
          handleInputChange={handleInputChange} input={input} 
          handleKeyPress={handleKeyPress} setOpenParameter={setOpenParameter} 
          openParameter={openParameter} handleSendMessage={handleSendMessage} 
          deployments={deployments} 
          inputPromptRef={inputPromptRef}
          chatBoxState={chatBoxState}
          fileList={fileList}
          stateBindToDeploymentModel={stateBindToDeploymentModel}
          onFileUploadChange={onFileUploadChange}
        />
      </BottomContainerStyled>
      <HelperButtonController 
        setOpenParameter={setOpenParameter} 
        openParameter={openParameter} 
        iconNode={<ControlOutlined />} 
        titleNode={<>{<ControlOutlined />} Parameters</>}
      >
        <BotsSlideParameters 
          stateBindToDeploymentModel={stateBindToDeploymentModel}
          showTemperature={true}
          showMaxLength={true}
          showTopProb={true}
          showContextHistory={true}
          showFreqPenality={true}
          showPresPenality={true}
          showBestOf={true}
        />
        <BotsInputParameter stateBindToDeploymentModel={stateBindToDeploymentModel} />

      </HelperButtonController>
    </FormStyled>
  );
};



/*
for sindari

<BotsSlideParameters 
  stateBindToDeploymentModel={stateBindToDeploymentModel}
  showTemperature={false}
  showMaxLength={false}
  showTopProb={false}
  showContextHistory={true}
  showFreqPenality={false}
  showPresPenality={false}
  showBestOf={false}
/>



*/

{/*
  For HR Chat Bot 
  {
          <Menu
            items={HrChatBotMenu}
            onClick={onSelectChatBotMenu}
            style={{display: HrChatBotMenuSelectKey !== null && 'none'}}
          />
        }
        {
          <HelperContentList activeKey={'0'} listContent={helpContent} currentKey={HrChatBotMenuSelectKey}
            titleNode={HrChatBotMenuSelectKey && HrChatBotMenu[HrChatBotMenuSelectKey].label} 
            handleBackButtton={e=>{setHrChatBotMenuSelectKey(null); setOpenParameter(true)}} />
        }
        {
          <HelperContentList activeKey={'1'} listContent={disclaimerContent} currentKey={HrChatBotMenuSelectKey}
            titleNode={HrChatBotMenuSelectKey && HrChatBotMenu[HrChatBotMenuSelectKey].label} 
            handleBackButtton={e=>{setHrChatBotMenuSelectKey(null); setOpenParameter(true)}} />
        }
        {
          <div style={{display: HrChatBotMenuSelectKey !== '2' && 'none'}}>
            <div className='helper-heading parameter-heading'>
              {<Button type="text" onClick={e=>{setHrChatBotMenuSelectKey(null); setOpenParameter(true)}} icon={<ArrowLeftOutlined />}></Button>}
              <h4>{HrChatBotMenuSelectKey && HrChatBotMenu[HrChatBotMenuSelectKey].label}</h4>
            </div>
          </div>
        } 
          */}

export default ChatBuilder;
