import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";
import { Spin, ConfigProvider } from "antd";
import Logon from "./pages/Logon";
import { config, deployments } from "./constants";
import { CenterBox } from "./components/Styles";
import UserContext from "./context";
import { ThemeProvider } from "styled-components";
import Theme, { ThemeToken } from "./stylesheets/Theme";
import GlobalStyles from "./stylesheets/Global.styled";
import GPTPlayground from "./pages/GPTPlayground";
import Home from "./pages/Home";
import HRChatBotBuddy from "./pages/HRChatBotBuddy";
import Sindri from "./pages/Sindri";
import Hermes from "./pages/Hermes";
import PageMeta from "./components/common/PageMeta";

function App() {
  const isGPTPlayground = window.location.hostname.includes("gpt-playground");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoader, setShowLoader] = useState(true); // Application Loading Boolean
  //const [user, setUser] = useState(null);
  const [userContext, setUserContext] = useState({ lastSelectedModel: deployments[2].value, user: null });


const ROUTES = [
  {
    path: "/",
    component: Home,
    title: "Bold Bots",
    favicon: "/favicon.png",
  },
  {
    path: "/gpt-playground",
    component: GPTPlayground,
    title: "GPT Playground",
    favicon: "/favicon.png",
  },
  {
    path: "/hr-policies",
    component: HRChatBotBuddy,
    title: "Bold HR Bot",
    favicon: "/icon-hr.png",
  },
  {
    path: "/confluence-arch",
    component: Sindri,
    title: "Sindri",
    favicon: "/sindri.png",
  },
  {
    path: "/chatbot-ttc",
    component: Hermes,
    title: "Hermes",
    favicon: "/hermes.png",
  },
]

  useEffect(() => {
    if (Cookies.get("auth_user")) {
      //console.log('auth check cookie found');
      setIsLoggedIn(true);
    }
    // else
    //   console.log('auth check cookie not found');
    setShowLoader(false);
  }, []);

  const handleLogout = () => {
    //console.log("e", config.ENV);
    if (config.ENV === "development") Cookies.remove("auth_user");
    else Cookies.remove("auth_user", { domain: ".bold.com" });
    setIsLoggedIn(false);
  };

  return (
    <Suspense fallback={<Spin tip="Loading" size="large" />}>
      <UserContext.Provider value={{ userContext, setUserContext }}>
        <ThemeProvider theme={Theme}>
          <ConfigProvider theme={{ token: ThemeToken }}>
            <GlobalStyles />
            {showLoader ? (
              <CenterBox>
                <Spin size="large" />
              </CenterBox>
            ) : (
              <Routes>
                {isGPTPlayground ? (
                  <Route
                    path="/"
                    index
                    element={
                      isLoggedIn ? (
                        <>
                          <GPTPlayground isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
                          <PageMeta title="GPT Playground" favicon="/favicon.png" />
                        </>
                      ) : (
                        <Logon />
                      )
                    }
                  />
                ) : (
                  ROUTES.map(({ path, component: Component, title, favicon }) => (
                    <Route
                      key={path}
                      path={path}
                      index={path === "/" ? true : false}
                      element={
                        isLoggedIn ? (
                          <>
                            <Component isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
                            <PageMeta title={title} favicon={favicon} />
                          </>
                        ) : (
                          <Logon />
                        )
                      }
                    />
                  ))
                )}
              </Routes>
            )}
          </ConfigProvider>
        </ThemeProvider>
      </UserContext.Provider>
    </Suspense>
  );
}

export default App;
